@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  font-family: "Open Sans", sans-serif;
}

.Header{
  position: sticky;
  // height: 100px;
  top: -100px; 
  transition: top 0.4s ease; 
  z-index: 1000;
  .nav-link{
    color: black !important;
    font-size: 18px;
    margin: 4px;
  }
  .logo{
    width: 60px !important;
    height: auto;
  }
  .nav-link:hover{
    color: #0d6efd !important;
  }

  .collapse{
    .items{
      display: flex;
    }
    .itemlist-1{
      border-right: 1px solid black !important;
    }
    
    .dropdown-item{
      font-family: "Open Sans", sans-serif;
      margin-bottom: 2px !important;
    }

    
    @media screen and (max-width: 600px){
     .dropdown-item{
      font-size: 9px;
     }

     .btn{
      display: none;
     }
    }
  }
}

.Header.sticky {
  top: 0; 
}


.Hero{
  background: url(./Media/services-banner.jpg);
  height: 90vh;
  .center-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
  }
  .hero-heading{
    text-align: center;
    color: white;
    font-family: "roboto", sans-serif;
  }
}

.Software{
  .center-heading{
   padding-top: 80px;
    
  }
  .hero-heading{
    text-align: center;
    font-family: "roboto", sans-serif;
  }

  .section1{
    margin-top: 70px;
    margin-bottom: 20px;
  }

  .sub-heading{
    display: flex;
  }

  .sub-heading1{
    margin-left: 20px;
  }

  .sub-heading h5{
    font-weight: 600;
  }

  @media screen and (max-width: 600px){
    .sec1-img{
      width: 290px;
      height: auto;
      margin-bottom: 40px;
    }
    
  }
}

.Application{
  .center-heading{
    padding-top: 80px;
     
   }
   .hero-heading{
     text-align: center;
     font-family: "roboto", sans-serif;
   }
 
   .section1{
     margin-top: 70px;
     margin-bottom: 20px;
   }
 
   .sub-heading{
     display: flex;
   }
 
   .sub-heading1{
     margin-left: 20px;
   }

   .sub-heading h5{
    font-weight: 600;
  }

   .pic2{
    display: flex;
    justify-content: center;
   }

   @media screen and (max-width: 600px){
    .sec2-img{
      width: 190px;
      height: auto;
      margin-top: 30px;
    }
   }
}

.About-us{
  margin-top: 70px;
  .about-heading{
    text-align: center;
    font-family: "roboto", sans-serif;
  }

  .about-text{
    margin-top: 30px;
  }
}

.Footer{
  background-color: rgb(0, 183, 255);
  padding-bottom: 20px;
  margin-top: 70px;
  height: 100%;
  color: white;
  
  .contact-heading{
    color: white;
    text-align: center;
    padding-top: 30px;
  }
  .footer-style{
    padding-top: 50px;
    color: white;
    font-family: "roboto", sans-serif;
    .goto-link a{
      font-weight: 600;
    }
    .address{
      font-weight: 600;
    }
  }

  .footer-image{
    display: flex;
    justify-content: center;
    text-align: center;
    width: 150px;
    height: auto;
    padding-top: 40px;
    margin-left: 70px;
  }

  .goto-link a{
    text-decoration: none;
    color: white;
  }

  @media screen and (max-width: 600px){
   .footer-image{
    display: none;
   }
  }
}

.Portfolio{
  text-align: center;
  // background-color: gainsboro !important;

  .card-img-top{
    height: 350px;
    background-size: cover;
  }

  .card-center{
    display: flex;
    justify-content: center;
  }

  .card-1{
    background-color: #c8c8c7;
  }
  .card-2{
    background-color: #e9c5c5;
  }
  .card-3{
    background-color: #d7c3e1;
  }

  .portfolio-heading{
    text-align: center;
    padding-top: 50px;
    padding-bottom: 30px;
    font-family: "roboto", sans-serif;
  }

  @media screen and (max-width: 600px){
    .card{
      margin-bottom: 40px;
    }
  }
}

.img-overflow{
  overflow: hidden;
}

.conditions{
  background-color: rgb(0, 183, 255);
  color: white;
  padding-bottom: 20px;
  h1{
    margin-top: 30px;
    text-align: center;
    font-family: "roboto", sans-serif;
    font-weight: 700;
  }

  .paragraph{
    margin-top: 20px;
    
    font-size: 20px;
    // text-align: center;
  font-weight: 600;
  }

  @media screen and (max-width: 600px){
    .paragraph{
      font-size: 15px;
    }
   }
}



.policy{
  background-color: rgb(0, 183, 255);
  color: white;
  padding-bottom: 20px;
  // height: 100hv;
  h1{
    margin-top: 30px;
    text-align: center;
    font-family: "roboto", sans-serif;
    font-weight: 700;
  }
  .paragraph{
    margin-top: 20px;
    font-size: 20px;
    // text-align: center;
  font-weight: 600;
  }

  @media screen and (max-width: 600px){
    .paragraph{
      font-size: 15px;
    }
   }
}